// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-hubungi-kami-via-whatsapp-tsx": () => import("./../../../src/pages/hubungi-kami-via-whatsapp.tsx" /* webpackChunkName: "component---src-pages-hubungi-kami-via-whatsapp-tsx" */),
  "component---src-pages-layanan-paket-pendirian-pt-padi-umkm-tsx": () => import("./../../../src/pages/layanan/paket-pendirian-pt-padi-umkm.tsx" /* webpackChunkName: "component---src-pages-layanan-paket-pendirian-pt-padi-umkm-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-tentang-kami-tsx": () => import("./../../../src/pages/tentang-kami.tsx" /* webpackChunkName: "component---src-pages-tentang-kami-tsx" */),
  "component---src-templates-artikel-badan-usaha-tsx": () => import("./../../../src/templates/artikel-badan-usaha.tsx" /* webpackChunkName: "component---src-templates-artikel-badan-usaha-tsx" */),
  "component---src-templates-artikel-detail-tsx": () => import("./../../../src/templates/artikel-detail.tsx" /* webpackChunkName: "component---src-templates-artikel-detail-tsx" */),
  "component---src-templates-artikel-pajak-perusahaan-tsx": () => import("./../../../src/templates/artikel-pajak-perusahaan.tsx" /* webpackChunkName: "component---src-templates-artikel-pajak-perusahaan-tsx" */),
  "component---src-templates-artikel-perizinan-berusaha-tsx": () => import("./../../../src/templates/artikel-perizinan-berusaha.tsx" /* webpackChunkName: "component---src-templates-artikel-perizinan-berusaha-tsx" */),
  "component---src-templates-artikel-tsx": () => import("./../../../src/templates/artikel.tsx" /* webpackChunkName: "component---src-templates-artikel-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-layanan-new-2-tsx": () => import("./../../../src/templates/layanan-new-2.tsx" /* webpackChunkName: "component---src-templates-layanan-new-2-tsx" */),
  "component---src-templates-layanan-new-3-tsx": () => import("./../../../src/templates/layanan-new-3.tsx" /* webpackChunkName: "component---src-templates-layanan-new-3-tsx" */),
  "component---src-templates-layanan-new-4-tsx": () => import("./../../../src/templates/layanan-new-4.tsx" /* webpackChunkName: "component---src-templates-layanan-new-4-tsx" */),
  "component---src-templates-layanan-new-cv-tsx": () => import("./../../../src/templates/layanan-new-cv.tsx" /* webpackChunkName: "component---src-templates-layanan-new-cv-tsx" */),
  "component---src-templates-layanan-new-tsx": () => import("./../../../src/templates/layanan-new.tsx" /* webpackChunkName: "component---src-templates-layanan-new-tsx" */),
  "component---src-templates-layanan-tsx": () => import("./../../../src/templates/layanan.tsx" /* webpackChunkName: "component---src-templates-layanan-tsx" */),
  "component---src-templates-syarat-tsx": () => import("./../../../src/templates/syarat.tsx" /* webpackChunkName: "component---src-templates-syarat-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

