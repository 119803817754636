import './src/styles/global.css';
import './src/styles/icon.css';
import { navigate } from 'gatsby';

function getExperimentVariant(expId) {
  if (typeof window !== undefined) {
    if (!window['gaData']) {
      return null;
    }
    const gaDataKeys = Object.keys(window['gaData']);
    if (!gaDataKeys && !gaDataKeys.length) {
      return null;
    }
    const gaId = gaDataKeys[0];
    const experiments = window['gaData'][gaId].experiments;
    if (!experiments) {
      return null;
    }
    const expIds = Object.keys(experiments);
    if (!expIds && !expIds.length) {
      return null;
    }
    const cohort = experiments[expId];
    if (!cohort) {
      return null;
    }
    return parseInt(cohort, 10);
  }
  return null;
}

function isExperimentAvailable() {
  if (typeof window !== undefined) {
    if (!window['gaData']) {
      return false;
    }
    const gaDataKeys = Object.keys(window['gaData']);
    if (!gaDataKeys && !gaDataKeys.length) {
      return false;
    }
    const gaId = gaDataKeys[0];
    const experiments = window['gaData'][gaId].experiments;
    if (!experiments) {
      return false;
    }
    const expIds = Object.keys(experiments);
    if (!expIds && !expIds.length) {
      return false;
    }
    return true;
  }
  return false;
}

function runExperiment(location) {
  const expId = 'FCWWeqXiQzCw-Tq2pAu2mA'; // please change this when experiment finish
  const variant = getExperimentVariant(expId);
  // redirect some cohort
  if (variant > 0) {
    const pathname = location.pathname;
    const isPaketPTCV = /(paket-easy-business|paket-pendirian-cv)\/?$/g?.test(pathname);
    const isHome = /^\/?$/g?.test(pathname);
    if (isPaketPTCV || isHome) {
      const newPathname = pathname.replace(/\/$/, '') + '-b';
      const targetUrl = newPathname + location.search + location.hash;
      navigate(targetUrl, { replace: true });
    }
  }
}

let gaAttempt = 0;
async function waitForGA(callback) {
  gaAttempt++;
  const gaexp = isExperimentAvailable();
  if (!gaexp && gaAttempt < 10) {
    // console.log('optimize availability check attempt -> ', gaAttempt);
    setTimeout(function () {
      waitForGA(callback);
    }, 1000);
    return;
  }
  if (!gaexp) {
    console.log('optimize experiment not available');
    return;
  }
  console.log('optimize experiment available!');
  callback();
}

export const onRouteUpdate = ({ location }) => {
  runExperiment(location);
};

export const onInitialClientRender = () => {
  // wait until ga are loaded then run experiment
  function runExp() {
    runExperiment(window.location);
  }
  waitForGA(runExp);
};
